import * as cs from './cs';
import * as en from './en';

export default {
	cs: {
		translation: cs,
	},
	en: {
		translation: en,
	},
};
